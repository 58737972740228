import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import '../styles/owl.theme.css';
import '../styles/global.css';

class BlogRoll extends Component {
  render() {
    const { data } = this.props;
    const { edges: blogs } = data.allMarkdownRemark;

    return (
      <div className="columns is-multiline">
        {blogs &&
          blogs.map(({ node: blog }) => (
            <div className="is-parent column is-6" key={blog.id}>
              <article
                className={`blog-list-item tile is-child box notification p-4`}
              >
                <header style={{ display: 'block' }}>
                  <h3 className="post-meta">{blog.frontmatter.title}</h3>
                  <p style={{ fontStyle: 'italic' }}>{blog.frontmatter.date}</p>
                </header>
                <p>
                  {blog.excerpt}
                  <br />
                  <br />
                  <Link className="button" to={blog.fields.slug}>
                    Keep Reading →
                  </Link>
                </p>
              </article>
            </div>
          ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
